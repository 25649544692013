<template>
    <div style="background: #fff">
        <a-button
            type="primary"
            style="position: absolute;top:11px;left:16px;"
            @click="addEventFn()"
        >添加事件
        </a-button
        >
        <a-calendar>
            <ul
                class="events"
                slot="dateCellRender"
                slot-scope="value"
                @click="addEventFn(23, value)"
            >
                <li
                    v-for="item in getListData(value)"
                    :key="item.content"
                    @click.stop="detailListFn(item.content)"
                >
                    <a-badge :status="item.type" :text="item.content"/>
                </li>
            </ul>

            <template slot="monthCellRender" slot-scope="value">
                <div v-if="getMonthData(value)" class="notes-month">
                    <section>{{ getMonthData(value) }}</section>
                    <span>Backlog number</span>
                </div>
            </template>
        </a-calendar>
        <a-modal
            title="添加事件"
            v-model="visible"
            :maskClosable="false"
            :footer="null"
        >
            <div style="height:460px;overflow:auto;">
                日期 :
                <a-date-picker
                    :defaultValue="moment(defaultDate, 'YYYY-MM-DD')"
                    format="YYYY-MM-DD"
                    @openChange="handleStartOpenChange"
                />
                <p style="height:500px;">465</p>
                <p>987</p>
                <p>123</p>
            </div>
        </a-modal>

        <a-modal title="详情" v-model="visibleDetails" :footer="null">
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
        </a-modal>
    </div>
</template>
<script>
import moment from 'moment'

export default {
    data() {
        return {
            visible: false,
            visibleDetails: false,
            defaultDate: null
            // mybarOption:{
            //   barWidth:6,
            //   barColor: '#f00'
            // }
        }
    },
    created() {
    },
    methods: {
        moment,
        getListData(value) {
            let listData
            switch (value.date()) {
                case 8:
                    listData = [
                        {type: 'warning', content: 'This is warning event.'},
                        {type: 'success', content: 'This is usual event.'}
                    ]
                    break
                case 10:
                    listData = [
                        {type: 'warning', content: 'This is warning event.'},
                        {type: 'success', content: 'This is usual event.'},
                        {type: 'error', content: 'This is error event.'}
                    ]
                    break
                case 15:
                    listData = [
                        {type: 'warning', content: 'This is warning event'},
                        {
                            type: 'success',
                            content: 'This is very long usual event。。....'
                        },
                        {type: 'error', content: 'This is error event 1.'},
                        {type: 'error', content: 'This is error event 2.'},
                        {type: 'error', content: 'This is error event 3.'},
                        {type: 'error', content: 'This is error event 4.'}
                    ]
                    break
                default:
            }
            return listData || []
        },
        //list 该条的详情数据
        detailListFn(content) {
            console.log(content)
            this.visibleDetails = true
        },
        //添加事件
        addEventFn(data, val) {
            console.log(data, val)
            this.visible = true
            if (val) {
                console.log(this.dateToComm(val._d))
                this.defaultDate = this.dateToComm(val._d)
                console.log(this.defaultDate)
            } else {
                console.log(this.dateToComm(new Date()))
                this.defaultDate = this.dateToComm(new Date())
                console.log(this.defaultDate)
            }
        },
        getMonthData(value) {
            if (value.month() === 8) {
                return 1394
            }
        },
        //日期转换
        dateToComm(datetime) {
            let year = datetime.getFullYear()
            let month = datetime.getMonth() + 1 //js从0开始取
            let date = datetime.getDate()
            // let hour = datetime.getHours();
            // let minutes = datetime.getMinutes();
            // let second = datetime.getSeconds();

            if (month < 10) {
                month = '0' + month
            }
            if (date < 10) {
                date = '0' + date
            }
            // if(hour <10){
            //   hour = "0" + hour;
            // }
            // if(minutes <10){
            //   minutes = "0" + minutes;
            // }
            // if(second <10){
            //   second = "0" + second ;
            // }
            year = year.toString()
            var time = year + '-' + month + '-' + date
            return time
        },
        // defaultChange(value,dateseting){
        //    console.log(value,dateseing)
        //    this.defaultDate = dateseting;
        // }
        handleStartOpenChange(val) {
            console.log(val)
            if (!val) {
                console.log('cxx', this.defaultDate)
            }
        }
    }
}
</script>
<style scoped>
.events {
    list-style: none;
    margin: 0;
    padding: 0;
}

.events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
}

.notes-month {
    text-align: center;
    font-size: 28px;
}

.notes-month section {
    font-size: 28px;
}

.events {
    min-height: 100%;
}
</style>
